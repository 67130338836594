import jQuery from "jquery";
import SimpleLightbox from "simple-lightbox";

import "simple-lightbox/dist/simpleLightbox.min.css";

jQuery(document).ready(function () {
    jQuery('[data-toggle="lightbox"]').simpleLightbox();
    jQuery("img.lightbox")
        .off("click.lightbox")
        .on("click.lightbox", function () {
            const element = jQuery(this);
            SimpleLightbox.open({
                content:
                    '<div class="contentInPopup"><img src="' +
                    element.attr("src") +
                    '"></div>',
            });
        });
});
